<template>
  <page-layout>
    <div id="receivingAdress">
      <a-spin :spinning="spinning">
        <a-row class="chooise_address" :gutter="[16, 16]">
          <a-col :xxl="4" :xl="6" :sm="12" v-for="(item, i) in list" :key="i">
            <div class="address_box">
              <div class="font14">{{ item.real_name }}</div>
              <div class="gray mart8">{{ item.phone }}</div>
              <div class="gray mart8">
                {{ item.province }} {{ item.city }} {{ item.district }}
                {{ item.detail }}
              </div>
              <div class="mart8">
                <span class="green" @click="$refs.editAddress.show(item)">
                  修改
                </span>
                <span class="red marginL8" @click="delect(item.id)">删除</span>
              </div>
            </div>
          </a-col>
          <a-col :xxl="4" :xl="6">
            <div class="address_box add" @click="$refs.editAddress.show()">
              <div>
                <a-icon type="plus-circle" theme="filled" />
                <div class="add_address">添加收货地址</div>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-spin>
      <edit-address ref="editAddress" @complete="getData" />
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";
import editAddress from "./editAddress.vue";
import { addressList, delAddress } from "@/api/receivingAdress";

export default {
  name: "receivingAdress",
  components: { PageLayout, editAddress },
  data() {
    return {
      list: [],
      spinning: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.spinning = true;
      addressList({
        page: 1,
        limit: 999,
      })
        .then((res) => {
          if (res.data.status == 200) {
            this.list = res.data.data.list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    delect(id) {
      let _this = this;
      this.$confirm({
        title: "提示",
        content: "确定要删除地址吗？",
        okText: "删除",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          delAddress({
            id,
          }).then((res) => {
            if (res.data.status == 200) {
              _this.$message.success("删除成功");
              _this.getData();
            } else {
              _this.$message.error(res.data.msg);
            }
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "receivingAdress";
</style>
